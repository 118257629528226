.navbar {
  background-color: black;
  position: relative;
}
.navbar-nav {
  display: flex;
  align-items: center;
}
.logo {
  width: 200px;
  height: 40px;
}
a.nav-link {
  color: #c7a35d;
}
input.me-2:focus {
  outline: 2px ridge #c7a35d;
}
input.form-control.me-2 {
  min-width: 250px;
}
input.me-2::placeholder {
  color: #c7a35d;
}
button.btn-outline-warning {
  color: #c7a35d;
  border: 1px solid #c7a35d;
}
ul.navbar-nav.me-auto.mb-2.mb-lg-0 {
  margin-bottom: 0 !important;
}
.authorization {
  position: absolute;
  right: 10px;
}
.navbar_input {
  position: relative;
  display: flex;
}
