.main {
  height: 100vh;
  padding-top: 10px;
}
.error {
  color: rgb(90, 90, 90);
  margin-bottom: 0;
  min-width: 200px;
}
.container {
  display: inline;
}
.container_description {
  padding: 5px;
  text-align: center;
  color: rgba(255, 255, 255, 0.75);
  box-shadow: 1px 1px 15px #c7a35d;
  font-size: 16px;
}
.container_title {
  margin-left: 20px;
  color: white;
  font-size: 20px;
  font-weight: 600;
}
