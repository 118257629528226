.slider {
  display: flex;
  justify-content: start;
}
.image {
  width: 250px;
  height: 380px;
  padding: 2px;
  border: 1px solid white;
  border-radius: 10px;
}
.slider_image {
  cursor: pointer;
}

.swiper {
  width: 100%;
  height: 100%;
}
.mySwiper {
  margin-left: 25px;
  margin-right: 25px;
}
.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: cover;
  margin-bottom: 20px;
  width: auto;
}
.swiper-button-next:after,
.swiper-button-prev:after {
  color: #c7a35d;
}
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: var(--swiper-pagination-bottom, -5px);
}
.swiper-pagination-bullet {
  background: var(--swiper-pagination-bullet-inactive-color, white);
}
.swiper-pagination-bullet-active {
  background: var(--swiper-pagination-color, #c7a35d);
}
