.container_filmPage {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.container_titles {
  margin-top: 15px;
}
.container_titles p {
  margin-bottom: 15px;
  color: #c7a35d;
  font-weight: 500;
}
.container_info {
  max-width: 600px;
  margin-left: 40px;
  margin-right: 70px;
}
.container_info h2 {
  margin-bottom: 20px;
}
.container_info div {
  margin-bottom: 15px;
}
.container_poster {
  position: relative;
}
.container_favorite_image {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 33px;
  height: 34px;
  border-radius: 5px;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.5);
}
.favorite_image {
  position: absolute;
  top: 3px;
  right: 2px;
  width: 25px;
  height: 25px;
  z-index: 2;
}
.container_share_image {
  top: 335px;
}
.telegram_image {
  top: -3px;
  right: -3px;
  width: 35px;
  height: 35px;
}
